@mixin arrow() {
  display: inline-block;
  background: transparent;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23a0a0a0;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, repeat;
  background-size: 9px 100%;
  height: 10px;
  width: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-indent: -9999px;
}

@mixin dropdown() {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.57em 100%;
  height: 34px;
  border-radius: 2px;
  border: solid 1px $border-color;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  padding: 0.25em 2em 0.25em 0.87em;

  &:focus {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%231AB394;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E ");
    border-color: $border-color;
    outline: none;
  }
  &::-ms-expand {
    display: none;
  }
}

@mixin input() {
  height: 34px;
  border: none;
  border-bottom: 0.06em solid $border-color;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  padding: 0.25em 1.5em 0.25em 0.87em;
  background: transparent;

  &:focus {
    border-color: $main-color;
    outline: none;
  }
}

.sa-table {
  font-size: $root-font-size;
  font-family: Segoe UI;

  button {
    cursor: pointer;
    background-color: inherit;
    outline: none;
  }

  select {
    @include dropdown();
  }

  input {
    @include input();
  }
}

.sa-table__detail-row.sa-table__detail-row.sa-table__detail-row.sa-table__detail-row {
  background-color: $detail-color;
}

.sa-table__detail-table.sa-table__detail-table {
  width: 100%;
  border-collapse: collapse;
  border-right: 1px solid $border-color;
  border-bottom: none;
}

.sa-table__detail {
  border-top: 1px solid $border-color;
  td {
    box-sizing: border-box;
    padding: 20px;
    border-left: none;
    border-right: none;
    white-space: initial;
  }

  td:first-child {
    width: 267px;
    border-left: none;
    font-weight: bold;
  }

  td:nth-child(2) {
    width: 208px;
  }
}

.sa-table__btn {
  height: 34px;
  width: 154px;
  font-size: $root-font-size;
  font-weight: bold;
  background-color: transparent;
  box-sizing: border-box;
  border: solid 2px;
  border-radius: 17px;
  padding: 0;
  outline: none;
}

a.sa-table__btn {
  display: inline-block;
  text-align: center;
  padding-top: 5px;
}

.sa-table__btn--small {
  width: 99px;
}

.sa-table__btn--gray {
  border-color: $text-gray;
  color: $text-gray;
  &:hover {
    background-color: $dark-gray;
    color: $text-color-inverse;
  }
}

.sa-table__btn--green {
  border-color: $main-color;
  color: $main-color;
  &:hover {
    background-color: $main-color;
    color: $text-color-inverse;
  }
}

.sa-table__svg-button {
  width: 15px;
  height: 15px;
  padding: 0;
  border: none;
  use {
    fill: $svg-color;
  }
  &:hover {
    use {
      fill: $svg-hover-color;
    }
  }
  svg {
    width: 15px;
    height: 15px;
  }
}

.sa-table__action-container {
  margin-bottom: 10px;
  white-space: normal;
  .sa-table__svg-button {
    float: right;
    margin: 0 0 13px 13px;
    pointer-events: all;
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  .sa-table__drag-button {
    float: left;
    cursor: move;
    use {
      fill: $main-color;
    }
    &:hover use {
      fill: $main-color;
    }
  }
}

.sa-table__global-filter.sa-table__global-filter,
.sa-table__filter.sa-table__filter {
  background: transparent;
  background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3C!-- Generator: Adobe Illustrator 24.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16 16' style='enable-background:new 0 0 16 16;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0{fill:%239F9E9E;} %3C/style%3E%3Cpath class='st0' d='M13.56,11.44L11.3,9.18C11.74,8.38,12,7.47,12,6.5C12,3.47,9.53,1,6.5,1S1,3.47,1,6.5C1,9.53,3.47,12,6.5,12 c0.97,0,1.88-0.26,2.68-0.7l2.26,2.26c0.29,0.29,0.66,0.43,1.04,0.44c0.39,0.01,0.78-0.14,1.08-0.44c0.3-0.3,0.44-0.69,0.44-1.08 C13.99,12.1,13.85,11.72,13.56,11.44z M4,6.5C4,5.12,5.12,4,6.5,4S9,5.12,9,6.5S7.88,9,6.5,9S4,7.88,4,6.5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  background-position: left 0.7em top 50%, 0 0;
  background-size: 1em 100%;
  padding-left: 35px;
  border: solid 1px $border-color;
  pointer-events: all;
}

.sa-table__filter.sa-table__filter {
  width: 100%;
}

.sa-table__show-column.sa-table__show-column {
  background: transparent;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16 16' style='enable-background:new 0 0 16 16;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:rgba(159, 159, 159, 0.5);%7D .st1%7Bfill:rgba(159, 159, 159, 0.5);%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st1' d='M8,2.03C3.63,2.03,0,8,0,8s3.63,6.03,8,6.03s8-6,8-6S12.37,2.03,8,2.03z M8,12c-2.58,0-5.13-2.81-6-4 c1.07-1.47,3.61-4,6-4c2.58,0,5.13,2.81,6,4C12.93,9.47,10.39,12,8,12z'/%3E%3Ccircle class='st1' cx='8' cy='8' r='3'/%3E%3C/g%3E%3C/svg%3E"),
    url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, no-repeat;
  background-position: left 0.7em top 50%, right 0.7em top 50%;
  background-size: 1em 100%, 0.57em 100%;
  padding-left: 35px;
  border: solid 1px $border-color;
  pointer-events: all;
  margin-left: 20px;
  &:focus {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16 16' style='enable-background:new 0 0 16 16;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:rgba(159, 159, 159, 0.5);%7D .st1%7Bfill:rgba(159, 159, 159, 0.5);%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st1' d='M8,2.03C3.63,2.03,0,8,0,8s3.63,6.03,8,6.03s8-6,8-6S12.37,2.03,8,2.03z M8,12c-2.58,0-5.13-2.81-6-4 c1.07-1.47,3.61-4,6-4c2.58,0,5.13,2.81,6,4C12.93,9.47,10.39,12,8,12z'/%3E%3Ccircle class='st1' cx='8' cy='8' r='3'/%3E%3C/g%3E%3C/svg%3E"),
      url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%231AB394;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E ") !important;
  }
}

.sa-table__entries {
  float: right;
  white-space: nowrap;
}

.sa-table__entries-label {
  font-weight: bold;
}

.sa-table__entries-label--right {
  margin-right: 5px;
}

.sa-table__entries-label--left {
  margin-left: 5px;
}

.sa-table__detail-row {
  background-color: $detail-color;
}

.sa-table__detail-table {
  background-color: $detail-color;
}

.sa-table input.sa-table__row-extension {
  height: auto;
}

.sa-table__row-extension + .sa-table__row-extension {
  margin-left: 10px;
  vertical-align: middle;
}

.sa-table__header-extensions {
  float: left;
  margin-left: 10px;
  width: calc(100% - 540px);
}

.sa-table__header-extension + .sa-table__header-extension {
  margin-left: 10px;
}

.sa-commercial {
  font-size: 12px;
  padding: 1em 0 0.5em 2em;
  svg {
    width: 20px;
    height: 20px;
    fill: $main-color;
  }
}
.sa-commercial__text {
  font-weight: bold;
  text-decoration: none;
  color: $main-color;
}
.sa-commercial__product {
  padding-left: 0.5em;
}
